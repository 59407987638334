import Layout2 from '@/layout/Layout2'

export default [{
  path: '/photos',
  component: Layout2,
  meta: { requiresAuth: true },
  children: [
    {
      path: 'upload/:id',
      component: () => import('@/components/photos/UploadPhotos.vue'),
      name: 'UploadPhotos',
      props: true
    },
    {
      path: 'album/:id',
      component: () => import('@/components/photos/Albums.vue'),
      name: 'Album',
      props: true
    }
  ]
}]
