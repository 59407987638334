import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store/store'
import axios from 'axios'
import Echo from 'laravel-echo'
import BootstrapVue from 'bootstrap-vue'
import globals from './globals'
import Popper from 'popper.js'

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.use(BootstrapVue)

// Global RTL flag
Vue.mixin({
  data: globals
})

window.Pusher = require('pusher-js')

window.Echo = new Echo({
  authEndpoint: 'https://zemmita-group.digitallibya.com/admin/broadcasting/auth',
  broadcaster: 'pusher',
  key: '3976393e046b102d6cd4',
  cluster: 'eu',
  encrypted: true,
  forceTLS: true,
  auth: {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
})

axios.defaults.withCredentials = true

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'Authorization': 'Bearer ' + localStorage.getItem('token')
}

axios.defaults.baseURL = 'https://zemmita-group.digitallibya.com/admin/'
// axios.defaults.baseURL = 'http://localhost:8000/'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
