import Layout2 from '@/layout/Layout2'

export default [{
  path: '/',
  component: Layout2,
  meta: { requiresAuth: true },
  children: [
    {
      path: 'inventory',
      component: () => import('@/components/vehicles/Inventory.vue')
    },
    {
      path: 'process',
      component: () => import('@/components/vehicles/Process.vue')
    },
    {
      path: 'transit',
      component: () => import('@/components/vehicles/Transit.vue')
    },
    {
      path: 'arrived',
      component: () => import('@/components/vehicles/Arrived.vue')
    },
    {
      path: 'archive',
      component: () => import('@/components/vehicles/Archive.vue')
    },
    // {
    //     path: 'issues',
    //     component: () => import('@/components/vehicles/TitleIssues.vue'),
    // },
    {
      path: 'create',
      component: () => import('@/components/vehicles/crud/CreateCar.vue'),
      name: 'createCar'
    },
    {
      path: 'edit/:id',
      component: () => import('@/components/vehicles/crud/EditCar.vue'),
      name: 'editCar',
      props: true
    },
    {
      path: 'searchResult',
      component: () => import('@/components/vehicles/SearchResult.vue'),
      name: 'SearchResult',
      props: true
    }
  ]
}]
