import Layout2 from '@/layout/Layout2'

export default [{
  path: '/users',
  component: Layout2,
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      component: () => import('@/components/users/Users')
    },
    {
      path: 'create',
      component: () => import('@/components/users/CreateUser'),
      name: 'createUser'
    },
    {
      path: 'edit/:id',
      component: () => import('@/components/users/EditUser'),
      name: 'editUser',
      props: true
    }
  ]
}]
