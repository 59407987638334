import Layout2 from '@/layout/Layout2'

export default [{
  path: '/locations',
  component: Layout2,
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      component: () => import('@/components/locations/Locations')
    },
    {
      path: 'create',
      component: () => import('@/components/locations/CreateLocation'),
      name: 'createLocation'
    },
    {
      path: 'edit/:id',
      component: () => import('@/components/locations/EditLocation'),
      name: 'editLocation',
      props: true
    }
  ]
}]
