import Layout2 from '@/layout/Layout2'

export default [{
  path: '/invoices',
  component: Layout2,
  meta: { requiresAuth: true },
  children: [
    {
      path: 'unpaid',
      component: () => import('@/components/invoices/Invoices')
    },
    {
      path: 'paid',
      component: () => import('@/components/invoices/PaidInvoices')
    },
    {
      path: 'create',
      component: () => import('@/components/invoices/CreateInvoice'),
      name: 'createInvoice'
    },
    {
      path: 'edit/:id',
      component: () => import('@/components/invoices/EditInvoice'),
      name: 'editInvoice',
      props: true
    }
    // {
    //   path: 'print/:id',
    //   component: () => import('@/components/invoices/PrintInvoice'),
    //   name: 'printInvoice',
    //   props: true
    // }
  ]
}]
