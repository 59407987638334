import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import NotFound from '@/components/NotFound'
import axios from 'axios'
import globals from '@/globals'

import dashboardsRoutes from './dashboards'
import layoutsRoutes from './layouts'
import typographyRoutes from './typography'
import uiRoutes from './ui'
import formsRoutes from './forms'
import tablesRoutes from './tables'
import chartsRoutes from './charts'
import iconsRoutes from './icons'
import miscellaneousRoutes from './miscellaneous'
import pagesRoutes from './pages'
import completeUiRoutes from './complete-ui'
import usersRoutes from './users'
import vehiclesRoutes from './vehicles'
import carAlbumPhotos from './photos'
import locationRoutes from './location'
import InvoiceRoutes from './invoice'
import ProfileRoutes from './profile'
import PortRoutes from './port.js'
import ShippingRoutes from './shipping.js'
import DocsRoutes from './documents.js'
import PrintRoutes from './print.js'
import ContainerRoutes from './containers.js'
import OfficeRoutes from './offices'
// Authentication Router
import authRouter from './auth'
// Vuex Store
import store from '@/store/store'

Vue.use(Router)
Vue.use(Meta)

const ROUTES = [
  // Default route
  { path: '', redirect: '/overview' }
]
  .concat(dashboardsRoutes)
  .concat(layoutsRoutes)
  .concat(typographyRoutes)
  .concat(uiRoutes)
  .concat(formsRoutes)
  .concat(tablesRoutes)
  .concat(chartsRoutes)
  .concat(iconsRoutes)
  .concat(miscellaneousRoutes)
  .concat(pagesRoutes)
  .concat(completeUiRoutes)
  .concat(usersRoutes)
  .concat(vehiclesRoutes)
  .concat(carAlbumPhotos)
  .concat(authRouter)
  .concat(locationRoutes)
  .concat(InvoiceRoutes)
  .concat(ProfileRoutes)
  .concat(PortRoutes)
  .concat(ShippingRoutes)
  .concat(DocsRoutes)
  .concat(PrintRoutes)
  .concat(ContainerRoutes)
  .concat(OfficeRoutes)

// 404 Not Found page
ROUTES.push({
  path: '*',
  component: NotFound
})

const router = new Router({
  base: '/',
  mode: 'history',
  routes: ROUTES
})

router.afterEach(() => {
  // Remove initial splash screen
  const splashScreen = document.querySelector('.app-splash-screen')
  if (splashScreen) {
    splashScreen.style.opacity = 0
    setTimeout(() => splashScreen && splashScreen.parentNode.removeChild(splashScreen), 300)
  }

  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')
  // check if the route requires authentication and user is not logged in
  if (to.matched.some(route => route.meta.requiresAuth) && !store.state.isAuthenticated) {
    next({ name: 'Login' })
    return
  }
  // if logged in redirect to dashboard
  if (to.path === '/login' && store.state.isAuthenticated) {
    next({ path: '/overview' })
    return
  }
  next()

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})

axios.interceptors.response.use(
  function (response) {
    return response
  }
  // function (error) {
  //   console.log(error.response.status)
  //   if (error.response.status === 401) {
  //     localStorage.removeItem('token')
  //     window.location.reload()
  //     router.push({ name: 'Login' })
  //   }
  //   return Promise.reject(error)
  // }
)

export default router
