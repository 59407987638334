import Layout2 from '@/layout/Layout2'

export default [{
  path: '/shippingCompanies',
  component: Layout2,
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      component: () => import('@/components/shippingcompany/ShippingCompany')
    },
    {
      path: 'create',
      component: () => import('@/components/shippingcompany/CreateShippingCompany'),
      name: 'createShippingCompany'
    },
    {
      path: 'edit/:id',
      component: () => import('@/components/shippingcompany/EditShippingCompany'),
      name: 'editShippingCompany',
      props: true
    }
  ]
}]
