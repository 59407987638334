import Layout2 from '@/layout/Layout2'

export default [{
  path: '/ports',
  component: Layout2,
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      component: () => import('@/components/ports/Ports')
    },
    {
      path: 'create',
      component: () => import('@/components/ports/CreatePort'),
      name: 'createPort'
    },
    {
      path: 'edit/:id',
      component: () => import('@/components/ports/EditPort'),
      name: 'editPort',
      props: true
    }
  ]
}]
