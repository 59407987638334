import Layout2 from '@/layout/Layout2'

export default [{
  path: '/containers',
  component: Layout2,
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      component: () => import('@/components/containers/Containers.vue')
    },
    {
      path: 'transit',
      component: () => import('@/components/containers/TransitContainers.vue')
    },
    {
      path: 'arrived',
      component: () => import('@/components/containers/ArrivedContainers.vue')
    },
    {
      path: 'archive',
      component: () => import('@/components/containers/ArchiveContainers.vue')
    },
    {
      path: 'docs/:id',
      component: () => import('@/components/containers/ContainerDocs.vue'),
      name: 'ContainerDocs',
      props: true
    },
    {
      path: 'docs/upload/:id',
      component: () => import('@/components/containers/UploadContainerDocs.vue'),
      name: 'UploadContainerDocs',
      props: true
    },
    {
      path: 'photos/:id',
      component: () => import('@/components/containers/ContainerPhotos.vue'),
      name: 'ContainerPhotos',
      props: true
    },
    {
      path: 'photos/upload/:id',
      component: () => import('@/components/containers/UploadContainerPhotos.vue'),
      name: 'UploadContainerPhotos',
      props: true
    },
    {
      path: 'showContainer/:id',
      component: () => import('@/components/containers/ShowContainer.vue'),
      name: 'ShowContainer',
      props: true
    }

  ]
}]
