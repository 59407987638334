<template>
  <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x">

    <!-- Brand -->
    <b-navbar-brand to="/" class="app-brand demo d-lg-none py-2 mr-4">
      <!-- <span class="app-brand-logo demo bg-white">
        <img :src="`${publicUrl}img/logos/logo-1.png`" width="50" alt="">
      </span> -->
      <span class="app-brand-text demo font-weight-normal ml-2">{{$store.state.appName}}</span>
    </b-navbar-brand>

    <!-- Sidenav toggle -->
    <b-navbar-nav class="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto" v-if="sidenavToggle">
      <a class="nav-item nav-link px-0 mr-lg-4" href="javascript:void(0)" @click="toggleSidenav">
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>

    <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

    <b-collapse is-nav id="app-layout-navbar">
      <!-- Divider -->
      <hr class="d-lg-none w-100 my-2">

       <b-navbar-nav class="align-items-lg-center">
        <!-- Search -->
        <label class="nav-item navbar-text navbar-search-box pl-2 py-0 active" style="background:#ebeef4;color:#000;width:400px;border-radius:4px;">
          <i class="ion ion-ios-search navbar-icon align-middle"></i>
          <span class="navbar-search-input pl-2">
            <input id="search-bar" type="text" class="form-control navbar-text mx-2 px-3 search" placeholder="Search..." autocomplete="off"
             v-model="keywords" @keyup.enter="generalSearch" style="background:#ebeef4;color:#000;width:400px;border-radious:4px;">
          </span>
        </label>
      </b-navbar-nav>

      <b-navbar-nav class="align-items-lg-center ml-auto">
        <b-link to="/create" class="btn btn-primary rounded-pill">
        <span class="ion ion-md-add"></span>
        NEW VEHICLE</b-link>
        <!-- <button type="button" class="btn btn-primary rounded-pill">New Vehicle</button> -->
          <!-- <b-dd split variant="outline-primary" class="btn-round btn-block" text="Actions" right>
                <b-dd-item to="/cars/create">New Vehicle</b-dd-item>
                <b-dd-item > New Client</b-dd-item>
                <b-dd-item >Archive by containers</b-dd-item>
                <b-dd-item >Archive by vehicles</b-dd-item>
          </b-dd> -->
        <!-- Message Section -->
        <!-- Divider -->
        <!-- <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div> -->
        <b-nav-item-dropdown :right="!isRTL" class="demo-navbar-user">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <!-- <div v-if="this.$store.state.user.avatar === null" >
                <i class="ion d-block ion-ios-contact display-4 text-primary"></i>
              </div>
              <img v-else :src="this.$store.state.user.avatar" alt class="d-block ui-w-30 rounded-circle"> -->
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ this.$store.state.user.name}}</span>
              <!-- <span class="px-1 mr-lg-2 ml-2 ml-lg-0">Saad Allaghi</span> -->
            </span>
          </template>

          <!-- <b-dd-item><i class="ion ion-ios-person text-lightest"></i> &nbsp; My profile</b-dd-item> -->
          <!-- <b-dd-item><i class="ion ion-ios-mail text-lightest"></i> &nbsp; Messages</b-dd-item> -->
          <b-dd-item to="/account-settings"><i class="ion ion-ios-person text-lightest"></i> &nbsp; My profile</b-dd-item>
          <b-dd-divider />
          <b-dd-item :to="{name:'Logout'}"><i class="ion ion-ios-log-out text-primary"></i> &nbsp; Sign out</b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>

    <!-- CuiVueBlockui -->
    <BlockUI :html="html" v-if="isBusy"/>
    <!-- CuiVueBlockui -->

  </b-navbar>

</template>

<style>

.material-style .sidenav .app-brand.demo {
    height: 66px;
}

/* .layout-navbar {
    box-shadow:none;
}

.bg-navbar-theme {
    background-color: #f5f5f5 !important;
    color: #a3a4a6;
} */
.loading-container .loading-backdrop {
  background: #fff !important;
  opacity: .7 !important;
}
.loading-container .loading {
  background: none !important;
  box-shadow: none !important;
}

/* #search-bar::-webkit-input-placeholder {
  color: #fff !important;
}
#search-bar::-moz-placeholder {
  color: #fff !important;
}
#search-bar:-ms-input-placeholder {
  color: #fff !important;
}
#search-bar:-moz-placeholder {
  color: #fff !important;
} */

.ui-w-40 {
    width: 40px !important;
    height: 40px;
}
</style>

<style src="@/vendor/libs/spinkit/spinkit.scss" lang="scss"></style>

<script>
import Vue from 'vue'
import BlockUI from 'vue-blockui'
import axios from 'axios'
import ToastPlugin from 'bootstrap-vue'
Vue.use(BlockUI)
Vue.use(ToastPlugin)

export default {
  name: 'app-layout-navbar',

  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      profile: {},
      keywords: '',
      messsage: '',
      newMessages: 0,
      unreadMessages: '',
      latestMessageFrom: '',

      isBusy: false,
      // html:null,
      html: `
      <div class="sk-wave sk-primary">
        <div class="sk-rect sk-rect1"></div>
              <div class="sk-rect sk-rect2"></div>
              <div class="sk-rect sk-rect3"></div>
              <div class="sk-rect sk-rect4"></div>
              <div class="sk-rect sk-rect5"></div>    
        </div>
      <h5 class="text-center mb-0">Searching...</h5>`
    }
  },
  created () {
    this.getProfile()
  },

  mounted () {

  },

  watch: {
    // call again the method if the route changes
    '$route': 'getProfile'
  },

  methods: {
    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    },

    getLayoutNavbarBg () {
      return this.layoutNavbarBg
    },

    getProfile () {
      axios({
        method: 'get',
        url: '/api/profile'
      }).then(res => {
        const user = res.data.profile
        this.$store.commit('authSuccess', { user })
        this.profile = res.data.profile
      })
    },

    generalSearch () {
      var keywords = this.keywords
      this.$store.state.jsonData = []
      this.$store.state.originalJsonData = []
      this.$store.dispatch('searchVehicleAction', keywords)
      this.isBusy = true
      setTimeout(() => {
        this.isBusy = false
        this.$router.push({ name: 'SearchResult' })
      }, 1000)
    }

  }
}
</script>
