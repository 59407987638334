import LayoutBlank from '@/layout/LayoutBlank'

export default [{
  path: '/prints',
  component: LayoutBlank,
  meta: { requiresAuth: true },
  children: [
    // {
    //   path: '',
    //   component: () => import('@/components/invoices/Invoices')
    // },
    {
      path: 'invoice/:id',
      component: () => import('@/components/invoices/PrintInvoice'),
      name: 'printInvoice',
      props: true
    }
  ]
}]
