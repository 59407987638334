import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    appName: 'Zemmita Group',
    isAuthenticated: !!localStorage.getItem('token'),
    token: localStorage.getItem('token') || '',
    user: {},
    customers: null,
    dispatches: null,
    inventory: null,
    process: null,
    transit: null,
    arrived: null,
    archive: null,
    titleIssues: null,
    userRequests: null,
    keywords: null,
    jsonData: [],
    originalJsonData: [],
    isBusy: false,
    albumUrl: 'https://zemmitagroup.com/public/'
  },
  mutations: {
    authSuccess (state, payload) {
      state.user = payload.user
      state.token = payload.token
      state.isAuthenticated = true
    },

    authLogout (state, payload) {
      state.isAuthenticated = false
      localStorage.removeItem('token')
    },

    dashboardData (state, payload) {
      state.customers = payload.customers
      state.dispatches = payload.dispatches
      state.inventory = payload.inventory
      state.process = payload.process
      state.transit = payload.transit
      state.arrived = payload.arrived
      state.archive = payload.archive
      state.titleIssues = payload.titleIssues
      state.userRequests = payload.userRequests
    },

    searchVehicle (state, payload) {
      state.jsonData = payload.searchResult
      state.originalJsonData = payload.searchResult
      state.keywords = payload.keywords
    }
  },

  actions: {
    login ({ commit }, user) {
      axios({
        method: 'post',
        url: '/api/login',
        data: {
          email: user.email,
          password: user.password
        }
      }).then(response => {
        localStorage.setItem('token', response.data.token)
        const user = response.data.user
        const token = response.data.token
        commit('authSuccess', token, user)
      }).catch(error => {
        localStorage.removeItem('token')
        console.log(error.response)
      })
    },

    logout ({ commit }) {
      axios({
        method: 'post',
        url: '/api/logout'
      }).then(res => {
        localStorage.removeItem('token')
      }).catch(error => {
        console.log(error.response)
      })
    },

    getDashboardData ({ commit }) {
      axios({
        method: 'get',
        url: '/api/dashboard'
      }).then(response => {
        const customers = response.data.customers
        const dispatches = response.data.dispatches
        const inventory = response.data.inventory
        const process = response.data.process
        const transit = response.data.transit
        const arrived = response.data.arrived
        const archive = response.data.archive
        const titleIssues = response.data.titleIssues
        const userRequests = response.data.userRequests
        commit('dashboardData', { customers, dispatches, inventory, process, transit, arrived, archive, titleIssues, userRequests })
      }).catch(error => {
        console.log(error.response)
      })
    },

    searchVehicleAction ({ commit }, keywords) {
      axios.get('/api/vehicle/search/' + keywords)
        .then(res => {
          const searchResult = res.data.cars
          commit('searchVehicle', { searchResult, keywords })
        }).catch(error => {
          console.log(error)
        })
    }
  },

  getters: {

  }
})

export default store
