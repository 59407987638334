import Layout2 from '@/layout/Layout2'

export default [{
  path: '/documents',
  component: Layout2,
  meta: { requiresAuth: true },
  children: [
    {
      path: 'upload/:id',
      component: () => import('@/components/documents/UploadDocs.vue'),
      name: 'UploadDocs',
      props: true
    },
    {
      path: 'docs/:id',
      component: () => import('@/components/documents/Docs.vue'),
      name: 'Docs',
      props: true
    }
  ]
}]
