import Layout2 from '@/layout/Layout2'

export default [{
  path: '/offices',
  component: Layout2,
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      component: () => import('@/components/offices/Index'),
      name: 'Offices'
    },
    {
      path: 'create',
      component: () => import('@/components/offices/Create'),
      name: 'createOffice'
    },
    {
      path: 'edit/:id',
      component: () => import('@/components/offices/Edit'),
      name: 'editOffice',
      props: true
    }
  ]
}]
